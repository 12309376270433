import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';

// Epicbae
const firebaseConfig = {
  apiKey: 'AIzaSyAA2D_v8-sAopQRslRjS5mtwWlNi05l0Cs',
  authDomain: 'getzipp-18aec.firebaseapp.com',
  // databaseURL: 'https://epicbae-246b2.firebaseio.com',
  projectId: 'getzipp-18aec',
  storageBucket: 'getzipp-18aec.appspot.com',
  messagingSenderId: '6325301273',
  appId: '1:6325301273:web:63133bfdb863d89e377a88',
  measurementId: 'G-N0R2CRZMV5',
};

initializeApp (firebaseConfig);
const auth = getAuth ();
const db = getFirestore ();

export {auth, db};
