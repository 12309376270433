export const USER_FETCH = 'USER_FETCH';

export const SIGNUP_USER_REQUEST = 'SIGNUP_USER_REQUEST';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_ERROR = 'SIGNUP_USER_ERROR';

export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_ERROR = 'SIGNIN_USER_ERROR';

export const SIGNOUT_USER_REQUEST = 'SIGNOUT_USER_REQUEST';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SIGNOUT_USER_ERROR = 'SIGNOUT_USER_ERROR';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';
export const GET_ALL_SWIPE_USER_SUCCESS = 'GET_ALL_SWIPE_USER_SUCCESS';
export const GET_ALL_MATCH_USER_SUCCESS = 'GET_ALL_MATCH_USER_SUCCESS';
export const GET_ALL_SUBSCRIPTION_USER_SUCCESS =
  'GET_ALL_SUBSCRIPTION_USER_SUCCESS';
export const GET_ALL_SEEKER_REQUEST_SUCCESS = 'GET_ALL_SEEKER_REQUEST_SUCCESS';
export const GET_ALL_REPORTED_USER_SUCCESS = 'GET_ALL_MATCH_USER_SUCCESS';
